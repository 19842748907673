:root {
  /* --button-bg-color: #91dc27; */
  --button-bg-color: #91dc27;
  --text-color: #ffffff;
  --swap-btn-color: #91dc27;
  --req-bg: url();
}

body {
  color: var(--text-color);
}

.required-bg {
  background-image: var(--req-bg);
  background-size: cover;
  background-repeat: no-repeat;
  background-color: rgba(0, 0, 0, 0.6);
  background-blend-mode: overlay;
}

#main-block {
  width: 500px;
  margin: 0 auto;
  background: rgba(445, 445, 445, 0.5);
  padding: 20px;
  color: #ffffff;
  /* margin-top: 50px; */
}

#main-block .formBox {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  text-align: center;
  margin-bottom: 20px;
}

#main-block .subdomain {
  border: 0;
  width: 100%;
  padding: 10px;
  background: #fff;
  border-radius: 3px;
}

#main-block .formBox .block {
  width: 50%;
  position: relative;
  margin: 0 10px;
}

#main-block .formBox .color-Box {
  margin: 0 auto;
  height: 50px;
  background: chartreuse;
  cursor: pointer;
}

#main-block .formBox .color-picker {
  position: absolute;
  bottom: 50px;
}

.form-button {
  text-align: center;
}

.clone-button {
  border: 0;
  margin: 0;
  padding: 10px;
  font-size: 12px;
  width: auto;
  margin-right: 20px;
}

.clone-form-button {
  display: inline-block;
  margin: 0 10px;
}

.smartSwap .menuSideBar.subdomain .msbLinkSubBox .footerV2-LanBoxM .footerV2-LanBoxLink {
  color: var(--text-color);
  border: 1px solid var(--text-color);
  background: var(--button-bg-color);
}

.smartSwap .menuSideBar.subdomain .msbLinkSubBox .footerV2-LanBoxM .footerV2-LanBoxLink:hover {
  filter: brightness(1);
}

.smartSwap .menuSideBar.subdomain {
  background-color: transparent;
}

.smartSwap .menuSideBar.subdomain::before {
  content: "";
  background-color: var(--button-bg-color);
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  opacity: 0.9;
  filter: brightness(0.3);
}

.smartSwap .menuSideBar.subdomain .msb-Linkbox01 a {
  color: var(--text-color);
}

.smartSwap .menuSideBar.subdomain .msb-Linkbox01 a:hover {
  color: var(--text-color);
  text-decoration: underline;
}

.smartSwap .menuSideBar.subdomain .msb-Linkbox02 a:hover {
  color: var(--button-bg-color);
  filter: brightness(3);
}

.smartSwap .menuSideBar.subdomain .msbLinkSubBox a {
  color: var(--text-color);
}

.smartSwap .menuSideBar.subdomain .msbLinkSubBox a:hover {
  color: var(--button-bg-color);
  filter: brightness(3);
}

.subdomain .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: var(--button-bg-color);
}

.required-bg a#new-Swap {
  color: var(--button-bg-color);
}

.required-bg .swap-Link01,
.required-bg .swap-Link01 a {
  color: var(--text-color);
}

.required-bg a.connect {
  position: relative;
  background-color: transparent !important;
}

.required-bg a.connect::before {
  content: "";
  background-color: var(--button-bg-color);
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
}

.required-bg a.connect.swapBtn::before {
  content: "";
  background-color: var(--swap-btn-color);
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
}

.required-bg a:hover.connect.swapBtn::before {
  filter: brightness(0.8) !important;
}

.required-bg a:hover.connect::before {
  filter: brightness(0.8);
}

/* .required-bg a.connect.swap::before {
  filter: brightness(2);
} */
.required-bg a:hover.connect.swap::before {
  filter: brightness(1.7);
}

.bg-slider {
  position: relative;
  width: 100%;
  color: #a69cbe;
}

.bg-slider .percent {
  position: absolute;
  width: 40px;
  height: 40px;
  background: #91dc27;
  cursor: grab;
  border-radius: 50%;
  top: -8px;
  z-index: -1;
  transform: translateX(-29px);
  transform: translateX(-29px);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  border: 3px solid #fff;
  box-shadow: 0 0 10px rgb(145 220 39 / 70%);
  color: white;
}

.bg-slider .progress {
  background: rgb(54 56 64 / 90%);
  height: 27px;
  position: absolute;
  left: 0;
  top: 0px;
  z-index: -1;
}

.bg-slider .bar {
  background: #16171e;
  height: 27px;
  position: absolute;
  left: 0;
  top: 0px;
  z-index: -1;
  width: 100%;
}

.bg-slider .text {
  height: 30px;
  position: absolute;
  left: 8px;
  top: -2px;
  z-index: -1;
  line-height: 30px;
  color: #a195b9;
}

.bg-slider .text.right {
  left: auto;
  right: 5px;
  color: #9087a4;
}

.overlay-slider {
  -webkit-appearance: none;
  /* Override default CSS styles */
  appearance: none;
  width: 100%;
  /* Full-width */
  height: 30px;
  /* Specified height */
  outline: none;
  /* Remove outline */
  opacity: 0 !important;
  /* Set transparency (for mouse-over effects on hover) */
  margin-bottom: 20px;
  border-radius: 5px;
  background: transparent !important;
}

/* Mouse-over effects */
.overlay-slider:hover {
  opacity: 0;
  /* Fully shown on mouse-over */
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.overlay-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  /* Override default look */
  appearance: none;
  width: 50px;
  /* Set a specific slider handle width */
  height: 50px;
  /* Slider handle height */
  /* background: #dc41dc; */
  cursor: grab;
  /* Cursor on hover */
  border-radius: 50%;
  /* position: relative;
  z-index: 3; */
  background: transparent;
}

.overlay-slider::-moz-range-thumb {
  width: 25px;
  /* Set a specific slider handle width */
  height: 25px;
  /* Slider handle height */
  background: #91dc27;
  /* Green background */
  cursor: grab;
  /* Cursor on hover */
  border-radius: 50%;
  background: transparent;
}

.swapMulti {
  font-size: 11px;
  background: #000;
  padding: 10px;
  line-height: 1.6;
  color: #a69cbe;
}

.swapMultiP {
  font-size: 12px;
  line-height: 1.6;
  color: #a69cbe;
}

.swapMultiTable {
  width: 100%;
  margin-top: 30px;
  font-size: 12px;
  color: #a69cbe;
}

tr {
  border-top: 1px solid #271e3a;
}

td {
  padding: 11.5px;
}

.lefttd {
  padding: 11.5px 11.5px 11.5px 0px;
}

.color-picker {
  position: absolute;
  top: 61px;
  z-index: 2;
  right: 2px;
}

.colorPlater {
  position: relative;
}

.chrome-picker {
  min-width: 246px;
}

.overPicker {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
}

.smartSwap .logo a img {
  margin: 0px;
  max-width: 258px;
  max-height: 50px;
  width: unset;
}

.smeTitle01 {
  color: var(--text-color);
}

.smeTitle01 span {
  color: var(--button-bg-color);
}

.smvTitle02 {
  color: var(--text-color);
}

.auditLogo.subdomain {
  border-bottom: 1px solid var(--button-bg-color);
}

.smartSwap .swap-Wrap .swap-Box .swap-Input .swap-Title .swap-Amt .currecy {
  background: var(--button-bg-color);
  color: #fff;
}

.smartSwap .swap-Wrap .swap-Box .coin-Box01:hover .swap-Coin:before {
  border: 3px solid var(--button-bg-color);
}

.smartSwap .swap-textBox .swap-boxWrap .swap-Box01 .text-Content p {
  color: var(--text-color);
}

.smartSwap .langBoxContainer.subdomain {
  background-color: transparent;
}

.smartSwap .langBoxContainer.subdomain::before {
  content: "";
  background: var(--button-bg-color);
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  opacity: 0.9;
  filter: brightness(0.3);
}

.smartSwap .swap-textBox .swap-boxWrap .swap-Box01 .icon-Box {
  background: none;
  width: 55px;
  height: 55px;
  /* transform: scale(1.2); */
}

.st0 {
  filter: brightness(2);
}

.st1 {
  filter: brightness(0.9);
}

.smartSwap .swap-Wrap .swap-Icon a {
  background: none;
}

.smartSwap .orange-Color {
  color: var(--text-color) !important;
}

.smartSwap .langBoxContainer.subdomain {
  background-color: transparent;
}

.smartSwap .langBoxContainer.subdomain::before {
  content: "";
  background: var(--button-bg-color);
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  opacity: 0.9;
  filter: brightness(0.3);
}

@media (min-width: 992px) and (max-width: 1199px) {
  .smartSwap .logo a img {
    max-width: 220px;
    max-height: 60px;
    width: unset;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .smartSwap .logo a img {
    max-width: 250px;
    max-height: 50px;
    width: unset;
  }
}